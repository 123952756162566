import { render, staticRenderFns } from "./Miranda-Do-Douro.vue?vue&type=template&id=9999762a&scoped=true&"
import script from "./Miranda-Do-Douro.vue?vue&type=script&lang=js&"
export * from "./Miranda-Do-Douro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9999762a",
  null
  
)

export default component.exports